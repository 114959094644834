@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  font-family: "Outfit", sans-serif;
  width: 100vw;
  overflow-x: hidden;
}
.additionalSmall {
  height: 110px;
}

.flagAnglia {
  margin-left: 2px;
}

.btn-scrollTop {
  position: fixed;
  bottom: 40px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #886cff;
  right: 2%;
  background: none;
  border-radius: 50px;
  padding: 0px;
  border: none;
  opacity: 1;
}
#header.small {
  width: 100vw;
  background-color: white;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: 1000ms ease;
  animation: fadein 0.5s;
}
.logoHeader {
  max-height: 80px;
}

.categoriesTech {
  display: flex;
  margin-top: 35px;
  justify-content: center;
  gap: 5px;
}
.technology {
  margin-bottom: 20px;
}
.categoriesTech button {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 20px 60px -6px rgba(0, 0, 0, 0.137);
  color: black;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1px;
  padding: 12px 24px;
  border: none;
}
.categoriesTech button#active,
.categoriesTech button:hover {
  border-radius: 12px;
  color: white;
  background: #4f77ff;
  box-shadow: 0px 20px 60px -6px rgba(0, 0, 0, 0.04);
}
body a,
body a:hover {
  text-decoration: none;
  color: #212121;
}
.blackcolor {
  color: #212121;
}
#header,
#footer,
.mainHero {
  background-color: #f7f5f1;
}
.flag {
  max-height: 20px;
}
.menuDeskop a {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
  padding: 5px 20px 10px;
}
.menuDeskop .languages {
  padding: 5px 20px 10px;
}
.menuDeskop a.active {
  background: url("/public/assets/activeMenu.png") center center no-repeat;
  background-size: contain;
}
.heroHome {
  background-color: #f5faff;
}

.leftSideHeroHomeImg {
  max-height: 75px;
  width: 150px;
}
.heroHome p.purpleFont {
  color: #8771fe;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.heroHome .leftSideInner h1 {
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
  background: linear-gradient(186deg, #00e9e9 0.38%, #886cff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.heroHome p {
  color: #374168;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  letter-spacing: -1px;
}
.buttonsHero .contant {
  color: var(--text-text-white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Link */
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
  border-radius: 5px;
  background: #8770ff;
  padding: 12px 22px;
  border: 1px solid #8770ff;
}

.buttonsHero .services {
  color: #212121;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  background: #f5faff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
  padding: 12px 22px;
  border-radius: 5px;
  border: 1px solid #212121;
}

.changeArrow {
  color: #374168;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 192.857% */
  letter-spacing: 4px;
  text-transform: uppercase;
}
.home2sec h2 {
  color: var(--gray-900, #16243e);
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
}
.home2sec p {
  color: #374168;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

h3.deliver {
  color: var(--gray-900, #16243e);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
}
h3.deliver span {
  background: url("/public/assets/activeMenu.png") center center no-repeat;
  background-size: contain;
  background-position-y: 42px;
}
.contentHome {
  background: #ffffff;
}
.deliverCoint {
  background: #fff;
  box-shadow: 0px 16px 110px 0px rgba(5, 18, 66, 0.1);
}
.deliverCoint h5 {
  color: #212121;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 15px;
  /* Headline/Headline 2 */
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px; /* 127.778% */
  letter-spacing: -1px;
}

.deliverCoint p {
  color: #374168;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  padding: 0px 15px;
  /* Paragraph/Paragraph 2 */
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.deliverCoint p span {
  color: #886cff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.chooseUs {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 2 */
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px; /* 112.5% */
  letter-spacing: -2px;
}

.chooseUsSlider .swiperinner {
  border-radius: 20px;
  padding: 20px;
  border: 1px solid var(--lines-hr-line, rgba(97, 119, 152, 0.2));
  background: var(--text-text-white, #fff);
}
.homeSwiper .swiper-slide-next,
.mobileHomeSwapper .swiper-slide-active {
  border-radius: 20px;
  background: var(--text-color-white, #fff);

  /* Blue Shadow */
  box-shadow: 0px 20px 50px 0px rgba(79, 119, 255, 0.2);
}
.chooseUsSlider .swiperinner h6 {
  color: #212121;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  letter-spacing: -1px;
}
.chooseUsSlider .swiperinner p {
  color: #374168;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 2 */
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.iconswiper {
  font-size: 60px;
  color: #4f77ff;
}
.showServices {
  border-radius: 5px;
  background: #886cff;
  color: var(--text-text-white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  padding: 16px 22px;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
}

.homeSwiper .swiper-pagination-bullet-active {
  background: #886cff;
}
.homeSwiper .swiper-button-next {
  color: #886cff;
  right: 31%;
}

.homeSwiper .swiper-button-prev {
  color: #886cff;
  left: 31%;
}
#technology {
  background: #f8fafc;
}
.technology {
  min-height: 24px;
}
#technology h6 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 116.667% */
  letter-spacing: -2px;
}

.technology .tech {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 999;
}

.techStack {
  color: var(--gray-900, #16243e);
  font-feature-settings: "clig" off, "liga" off;

  font-family: Outfit;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px; /* 112.5% */
  letter-spacing: -2px;
}

.techRight p {
  color: #374168;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 2 */
  font-family: "DM sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.techRight p span {
  color: #886cff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.callnowbutton {
  border-radius: 10px;
  background: #886cff;
  padding: 12px 22px;
  color: var(--White, #fff);
  font-size: 14px;
  font-style: normal;
  border: none;
  font-weight: 600;
  line-height: 22.4px; /* 140% */
  letter-spacing: 0.8px;
}
.getToKnowUs {
  padding: 5rem 0;
}

.getToKnowUs h6 {
  color: #292930;
  font-family: "DM sans", sans-serif;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: 74px; /* 113.846% */
  letter-spacing: -1.95px;
}
form input:focus,
form textarea:focus {
  outline: none !important;
}
.tech img {
  max-width: 20px;
}
form input:not(input[type="checkbox"]) {
  height: 50px;
  padding: 10px 20px;
  border: 1px solid rgba(131, 131, 131, 0.288) !important;
  border-radius: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid;
  color: var(--Dark, #171151);
  font-family: "DM sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  box-shadow: 0 0 10px 0 rgba(131, 131, 131, 0.288);
  font-weight: 400;
  line-height: 26px; /* 173.333% */
}
form textarea {
  padding: 10px 20px;
  border: 1px solid rgba(131, 131, 131, 0.288) !important;
  box-shadow: 0 0 10px 0 rgba(131, 131, 131, 0.288);
  border-radius: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid;
  color: var(--Dark, #171151);
  font-family: "DM sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 173.333% */
}

form button {
  color: #fff;
  text-align: center;
  font-family: "DM sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  background: #886cff;
  padding: 12px 26px;
  border: 1px solid #886cff;
  border-radius: 5px;
}
#footer form button {
  padding: 0;
  background: #4f77ff;
  border-color: #4f77ff;
}
#footer form input {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  background: rgba(33, 33, 33, 0);
}
.socials img {
  max-width: 50px;
}

.formHomeRigfht p {
  color: #374168;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 2 */
  font-family: "DM sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.clients .relative {
  position: relative;
}
.rectangleImage {
  position: absolute;
  bottom: 20%;
  left: 50%;
}
.clients h6 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 116.667% */
  letter-spacing: -2px;
}

.clients > p {
  color: var(--text-text, #7b88a8);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 3 */
  font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  letter-spacing: -1px;
}

.reviews {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-right: 10px;
}
.review {
  width: 25%;
}

.review .reviewOcena {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 80% */
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.review .stars {
  margin-bottom: 20px;
}

.review h3 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  letter-spacing: -1px;
}

.review button {
  background: #886cff;
  margin-top: 10px;
  padding: 20px 30px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 426.667% */
  border: none;
}
.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid var(--lines-gray-line, #e7e5ea);
  background: var(--color-5, #fff);
  opacity: 0.5;
  padding: 20px;
  text-align: center;
  transition: all 1 ease-in-out;
}

.review:hover {
  opacity: 1;
  transition: all 1 ease-in-out;
  border-radius: 20px;
  background: var(--color-5, #fff);
  box-shadow: 0px 20px 50px 0px rgba(79, 119, 255, 0.2);
}
.review img.rounded {
  border-radius: 50% !important;
}

.review h2 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 10px;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  letter-spacing: -1px;
}

.review p.shadowed {
  color: var(--text-text, #7b88a8);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 2 */
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.flagContact {
  width: 10%;
}

.review h3 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 4 */
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.4px;
}

.review p {
  color: var(--text-text, #7b88a8);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.bottomHome {
  background: var(--bgbg-light, #f8fafc);
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomHome h5 {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;
  background: var(
    --Purple-Gradient,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bottomHome {
  position: relative;
}
.bottomHome svg {
  position: absolute;
  width: 100%;
  opacity: 0.7;
}
.bottomHome .bottomItem svg {
  position: relative;
  width: fit-content;
}
#header {
  z-index: 99999999999;
}
#header .menuDeskop a {
  color: #212121;
  font-feature-settings: "clig" off, "liga" off;

  /* Link */
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
}

#footer h6 {
  color: #212121;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 3 */
  font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  letter-spacing: -1px;
}

#footer a {
  color: #212121;
  font-feature-settings: "clig" off, "liga" off;

  /* Link Small */
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
}
#footer .container a,
#footer .container p {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
}
#footer .underlineFooter {
  color: #212121;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 2 */
  font-family: "DM sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.mainHero {
  position: relative;
  padding-bottom: 5rem;
}
.mainHero h1 {
  z-index: 9999;
  color: var(--text-heading, #2d3958);
  font-feature-settings: "clig" off, "liga" off;

  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
}
.outsourcing h1 span {
  background: var(
    --Purple-Gradient,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Outfit;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: -2px;
}

.mainHero p {
  color: var(--text-text, #7b88a8);
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.mainHero button {
  color: var(--text-text-white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Link */
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
  border: none;
  padding: 12px 26px;
}

.outsourcing button {
  border-radius: 5px;
  background: #886cff;
}

.mainHero svg {
  z-index: 0;
  position: absolute;
}
.mainHero img {
  z-index: 999;
}

.circle1 {
  top: 35%;
  right: 10%;
}

.circle2 {
  top: 90%;
  right: 65%;
}

.circle3 {
  top: 12%;
  right: 55%;
}

.circle4 {
  top: 10%;
  right: 90%;
}

.circle5 {
  top: 80%;
  right: 20%;
}

.circle6 {
  top: 65%;
  right: 48%;
}

.zIndexHight {
  z-index: 99;
}

.section3 {
  background: #f7f5f1;
}
.section3 .row div {
  position: relative;
}

.section3 .shape {
  position: absolute;
}

.section3 .shape1 {
  right: 0;
}

.section3 .shape2 {
  right: 80%;
  top: 40%;
}

.section3 .shape3 {
  right: 0;
}

.section3 .shape4 {
  right: 80%;
  top: 40%;
}

.section3 .shape5 {
  right: 0;
}

.shapeproces1,
.shapeproces2,
.shapeproces3,
.shapeproces4 {
  position: absolute;
}
.shapeproces1 {
  left: 80%;
  top: 10%;
}
.shapeproces2 {
  left: 10%;
}
.section4 {
  position: relative;
}
.error {
  color: red !important;
  text-align: left !important;
}
.success {
  color: green !important;
  text-align: left !important;
}
.shapeproces3 {
  right: 10%;
  top: 5%;
}

.shapeproces4 {
  left: 20%;
  top: 15%;
}
.section2main h2 {
  color: #16243e;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
}

.section2main h2 span {
  color: var(--action-purple, #886cff);
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: -2px;
}

.section2main p {
  color: #374168;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.section2main .changeArrow {
  color: #374168;
  font-family: "DM sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 150% */
  letter-spacing: 4px;
  text-transform: uppercase;
}

.points .point p {
  width: calc(100% - 40px);
  color: var(--gray-900, #16243e);
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}
.section3 {
  padding-bottom: 4rem;
}
.section3 h2 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 3 */
  font-family: Outfit;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 116.667% */
  letter-spacing: -2px;
}
.section3 .row {
  margin-top: 3rem;
}

.section3 .row h4 {
  margin-top: 40px;
  color: #2d3958;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 2 */
  font-family: Outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px; /* 127.778% */
  letter-spacing: -1px;
}

.section3 .row p {
  color: var(--black-200, #788190);
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 2 */
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.section4 h4 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 20px;
  /* Display/Display 3 */
  font-family: Outfit;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 116.667% */
  letter-spacing: -2px;
}

.section4 p.legenda {
  color: #374168;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.section4 h2 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
}

.section4 h2 span {
  background: var(
    --Purple-Gradient,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: -2px;
}

.totheleft {
  position: relative;
}

.leftSideSVG {
  position: absolute;
  left: -150px;
}

.section4 img {
  margin: 20px 0;
  max-height: 250px;
}
.section4 .sectionBotMain img {
  max-height: unset;
}
.section4 h3 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 2 */
  font-family: Outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px; /* 127.778% */
  letter-spacing: -1px;
}

.section4 p {
  color: #374168;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM Sans", sans-serif;
  margin-top: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.sectionBotMain h4 {
  color: #16243e;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px; /* 108.333% */
  letter-spacing: -2px;
}

.sectionBotMain h4 span {
  color: var(--action-purple, #886cff);
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: -2px;
}
.sectionBotMain .points {
  gap: 10px;
}
.sectionBotMain .points .point p {
  text-align: left;
  margin-top: 0;
  color: var(--gray-900, #16243e);
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.contactUsSection.contactusTeam button {
  background: var(
    --Orange-Gradient,
    linear-gradient(93deg, #ffb95b 1.46%, #da8412 99.26%)
  );
  border: none;
}
.contactUsSection.contactusTeam h3 span {
  background: url("/public/assets/Vectoryellowline.png") center center no-repeat;
  background-size: contain;
  background-position-y: 30px;
}
.contactUsSection {
  background: #f8fafc;
  padding: 5rem 0;
}

.contactUsSection h3 {
  color: var(--text-heading, #2d3958);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 3 */
  font-family: Outfit;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 116.667% */
  letter-spacing: -2px;
}

.contactUsSection h3 span {
  background: url("/public/assets/activeMenu.png") center center no-repeat;
  background-size: contain;
  background-position-y: 30px;
}

.contactUsSection p {
  color: #374168;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Paragraph/Paragraph 1 */
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.business .small {
  color: #8771fe;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}
.business h1 span {
  background: var(
    --gradients-gradient-violet,
    radial-gradient(
      898.94% 187.34% at -10.97% -22.81%,
      #9b9cf8 0%,
      #8082ed 100%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-family: Outfit;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: -2px;
}
.mainHero.business button {
  border-radius: 5px;
  background: var(
    --Purple-Gradient,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
}
.businessWhyWave {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.businessWhyWave svg {
  max-width: 200px;
}

.setting h1 span {
  background: var(
    --Orange-Gradient,
    linear-gradient(93deg, #ffb95b 1.46%, #da8412 99.26%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;
}

.setting button {
  border-radius: 5px;
  background: var(
    --Orange-Gradient,
    linear-gradient(93deg, #ffb95b 1.46%, #da8412 99.26%)
  );
}

h2.setting span {
  background: var(
    --Orange-Gradient,
    linear-gradient(93deg, #ffb95b 1.46%, #da8412 99.26%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 1 */
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;
}

.setting p.small {
  color: var(--action-orange, #ff9513);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.setting3 h4 span {
  color: var(--action-orange, #ff9513);
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 2 */
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -1px;
}

.section4.s4setting h2 span {
  background: var(
    --Orange-Gradient,
    linear-gradient(93deg, #ffb95b 1.46%, #da8412 99.26%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
}

.sectionBotMain h4.settingBotMain span {
  color: var(--action-orange, #ff9513) !important;
  font-feature-settings: "clig" off, "liga" off;
}

.recruitement button {
  border-radius: 5px;
  background: var(--action-turquoise, #61dcdf);
}
.recruitement h1 span {
  color: var(--action-turquoise, #61dcdf) !important;
}
h2.recruitement span {
  color: var(--action-turquoise, #61dcdf) !important;

  /* Display/Display 1 */
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;
}

.recruitement p.small {
  color: var(--action-turquoise, #61dcdf);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.recruitement3 h4 span {
  color: var(--action-turquoise, #61dcdf) !important;
}

.section4.s4recruitement h2 span {
  color: var(--action-turquoise, #61dcdf) !important;
  -webkit-text-fill-color: #61dcdf;
  background: none !important;
}

.sectionBotMain h4.recruitementBotMain span {
  color: var(--action-turquoise, #61dcdf) !important;
}

.sectionBotMain h4 {
  text-align: left;
}

.software .small {
  color: var(--text-dark-alt, #321370);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.3px;
}

.software h1 span,
.software h2 span {
  color: #321370;
}

.software button {
  border-radius: 5px;
  background: var(--text-dark-alt, #321370);
}

.software4 h2 span {
  background: var(
    --Developer,
    linear-gradient(145deg, #321370 0.53%, #c1bcca 79.54%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sectionBotMain.sectionBotSoftware h4 span {
  color: var(--text-dark-alt, #321370);
  font-feature-settings: "clig" off, "liga" off;
}
#contactPAGE {
  background: #f5faff;
}
.heroContact {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5faff;
  height: 60vh;
}
.sunContact {
  right: 20%;
}
.wavesContact {
  right: 75%;
  top: 70%;
}

.arrowsContact {
  right: 85%;
  top: 35%;
}

.heroContact svg {
  position: absolute;
}
.contactusContact {
  padding-top: 14rem;
}

.contactusContact button {
  background: var(
    --Niebiesko-fioletowy,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
  border: none;
}

.contactUsSection.contactusContact h3 span {
  background: url("/public/assets/VectorcontactContact.png") center center
    no-repeat;
  background-position-y: 30px;
  background-size: contain;
}
.googleMap {
  width: 100%;
  height: 400px;
  margin-top: 3rem;
  margin-bottom: -10rem;
}
.dropdownLang .dropdown-menu {
  min-width: 150px;
  width: 150px;
}
.dropdownLang .dropdown-menu a img {
  width: 30px;
}
.dropdown-toggle img {
  width: 30px;
}
#header .menuDeskop a:active {
  background: #f7f5f1;
}
.heroContact h1 {
  background: var(
    --Niebiesko-fioletowy,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  /* Header 1 */
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px; /* 100% */
}
.dropdownLang .dropdown-toggle::after {
  content: "" !important;
  display: none !important;
}
.middleContact h2 {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 2 */
  font-family: Outfit;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px; /* 112.5% */
  letter-spacing: -2px;
  background: var(
    --Niebiesko-fioletowy,
    linear-gradient(186deg, #61dcdf 0.38%, #886cff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.middleContact h3 {
  color: var(--grey-900, #212121);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Display/Display 2 */
  font-family: Outfit;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px; /* 112.5% */
  letter-spacing: -2px;
}

.middleContact p {
  color: var(--grey-700, #616161);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  gap: 20px;
}
.middleContact p a {
  color: var(--grey-700, #616161);
}
.middleContact p span {
  font-size: 16px;
  width: 100%;
  text-align: start;
}
.hidetech {
  /* opacity: 0; */
  height: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.hidetech .tech {
  opacity: 0;
}

.shoptech .tech {
  opacity: 1;
}

.shoptech {
  max-height: 500px;
  /* opacity: 1;
  transition: opacity ease-in 500ms; */
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.middleContact .row div {
  background: var(--white, #fff);
  display: flex;
  padding: 30px 25px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  /* Shadow medium */
  box-shadow: 0px 6px 30px 0px rgba(33, 33, 33, 0.08);
}
.middleContact .row div section {
  width: 100%;
}
.googleMap {
  max-height: 500px;
}
.marker {
  font-size: 40px;
  color: #886cff;
}
.infoWindowWrapper p {
  margin-bottom: 0;
}
.infoWindowWrapper {
  padding: 10px 20px;
  font-size: 18px;
  color: var(--grey-900, #212121);
  text-align: center;
  font-weight: 500;
}
.middleContact h5 {
  color: var(--grey-900, #212121);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Headline/Headline 2 */
  font-family: Outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px; /* 127.778% */
  letter-spacing: -1px;
}
.poprawka {
  cursor: text;
}
.poprawka p {
  width: 150px;
  margin: 5px 0;
}
.poprawka div {
  font-size: 18px;
}

@media only screen and (max-width: 1659px) {
  .bottomHome h5 {
    font-size: 52px;
  }
}
@media only screen and (max-width: 1489px) {
  .bottomHome h5 {
    font-size: 46px;
  }
}

@media only screen and (max-width: 1400px) {
  .sunContact {
    right: 15%;
  }
  .getToKnowUs h6 {
    font-size: 52px;
  }
  .formHomeRigfht img {
    width: 80%;
  }
  body .noXl {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .sunContact {
    right: 5%;
  }
  .heroHome .leftSideInner h1 {
    font-size: 42px;
    line-height: 130%;
  }
  .heroHome p {
    font-size: 18px;
  }
  .home2sec h2 {
    font-size: 64px;
    line-height: 70px;
  }
  .technology {
    padding-left: unset;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .reviews {
    flex-wrap: wrap;
  }
  .review {
    width: calc(50% - 20px);
  }
  .circle1 {
    right: 2%;
  }
  .circle3 {
    right: 55%;
  }
  .section2main h2,
  .section2main h2 span {
    font-size: 50px;
    line-height: 110%;
  }
  .sectionBotMain h4,
  .sectionBotMain h4 span {
    font-size: 60px;
    line-height: 110%;
  }
  .section2main .changeArrow {
    letter-spacing: 2px;
    font-size: 12px;
    margin-bottom: 12px;
  }
  .section2main .points {
    margin-top: 15px;
  }
  .section3 .row {
    margin-top: 1.5rem;
  }
  .section4 p {
    max-width: 700px;
  }
  .contactUsSection {
    padding-top: 3rem;
  }
  .contactUsSection div {
    margin: auto;
    max-width: 700px;
  }
}

@media only screen and (max-width: 1098px) {
  .bottomHome h5 {
    font-size: 36px;
  }
  .bottomHome {
    height: 200px;
  }
}
.mobileHomeSwapper {
  display: none !important;
}
.menuMobile {
  display: none;
}
.drodownMobile {
  display: none;
}
@media only screen and (max-width: 992px) {
  .bottomHome .bottomItem svg {
    display: none;
  }
  .categoriesTech {
    flex-direction: column;
    gap: 10px;
  }
  body .technology {
    justify-content: flex-start !important;
  }
  body {
    width: 100vw;
    overflow-x: hidden;
  }
  .businessWhyWave {
    text-align: center;
    justify-content: center;
  }
  .businessWhyWave svg {
    display: none;
  }
  .section4 {
    padding-left: 10px;
    padding-right: 10px;
    width: 100vw;
  }
  .shapeproces1,
  .shapeproces2,
  .shapeproces3,
  .shapeproces4 {
    display: none;
  }
  .leftSideSVG {
    display: none;
  }
  .showServices {
    margin-bottom: 2rem;
  }
  .section3 .shape {
    display: none;
  }
  .heroContact svg.sunContact,
  .arrowsContact {
    display: none;
  }
  .heroContact {
    height: 30vh;
  }
  .drodownMobile {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999999;
    background: #f7f5f1;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .btn-scrollTop {
    font-size: 2.5rem;
    right: 5%;
  }
  .dropdownMobileItems {
    padding-left: 10px;
    color: #212121;
    font-feature-settings: "clig" off, "liga" off;
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* Link */
    font-family: Outfit;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
    text-transform: uppercase;
  }
  .dropdownMobileItems hr {
    opacity: 0;
  }
  .calendly-inline-widget {
    margin-top: 20px !important;
  }
  .languagess a {
    align-items: center;
    max-width: 33%;
  }
  .languagess a img {
    width: 30px;
    height: 100%;
  }
  .leftSideInner {
    justify-content: center !important;
    align-items: center !important;
    padding: 2rem 0;
    text-align: center;
  }
  body .menuMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
  }
  body .menuDeskop {
    display: none !important;
  }
  .noMd {
    display: none !important;
  }
  .home2sec {
    padding-top: 2rem;
    text-align: center;
  }
  .flexmdcenter {
    justify-content: center;
    align-items: center;
  }
  h3.deliver {
    font-size: 40px;
  }
  .section4 img {
    max-height: 200px;
  }
  .home2sec h2 {
    font-size: 40px;
    line-height: 100%;
    margin: 10px 0;
  }
  .chooseUs {
    font-size: 40px;
  }
  body .bottomspace {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  h3.deliver span {
    background-position-y: 25px;
  }
  .homeSwiper {
    display: none !important;
  }
  .home2sec p {
    font-size: 14px;
  }
  .getToKnowUs h6 {
    line-height: 120%;
  }
  #technology h6 {
    font-size: 40px;
  }
  .getToKnowUs {
    text-align: center;
  }
  .mobileHomeSwapper {
    display: block !important;
  }
  .technology {
    flex-wrap: wrap;
  }
  .techStack {
    margin-top: 20px;
  }
  .techStackImages {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .getToKnowUs {
    padding: 3rem 0;
  }
  .getToKnowUs h6 {
    font-size: 30px;
  }
  .getToKnowUs .row {
    flex-direction: column-reverse;
  }
  #technology h6 {
    text-align: center !important;
  }
  .review {
    width: 100%;
  }
  .bottomHome h5 {
    font-size: 22px;
  }
  .newsletter {
    margin-top: 10px;
  }
  #footer .logofooter {
    width: 100%;
    max-width: 300px;
  }
  body .centerMobile {
    margin: 20px 0;
    justify-content: center;
    align-items: center !important;
    text-align: center;
  }
  #footer .container .items {
    gap: 20px;
    flex-direction: column-reverse !important;
  }
  #footer .container p,
  #footer .container a {
    font-size: 12px;
  }
  .mainHero h1,
  .mainHero h1 span {
    text-align: center;
    font-size: 40px;
    line-height: 110%;
  }
  .mainHero {
    text-align: center;
    padding-top: 20px;
  }

  .mainHero button {
    margin-bottom: 20px;
  }
  .mainHero svg {
    display: none;
  }
  .section2main .row {
    flex-direction: column-reverse;
    text-align: center;
  }
  .section2main h2,
  .section2main h2 span {
    font-size: 40px;
  }
  .section2main .changeArrow {
    text-align: left;
    font-size: 14px;
  }
  .section2main .points {
    gap: 5px;
    margin-bottom: 30px;
  }
  .section3 h2 {
    font-size: 40px;
  }
  .section3 .row {
    margin-top: 30px;
    text-align: center;
  }
  .section3 .row h4 {
    margin-top: -30px;
  }
  .section3 .row.opposite {
    flex-direction: column-reverse;
  }
  .section4 h2,
  .section4 h2 span {
    font-size: 40px;
    line-height: 110%;
  }
  .section4 p {
    padding: 5px;
  }
  .section4 h3 {
    font-size: 30px;
    line-height: 110%;
  }
  .sectionBotMain {
    padding: 0 10px;
  }
  .sectionBotMain h4,
  .sectionBotMain h4 span {
    font-size: 40px;
  }
  .contactUsSection h3 {
    font-size: 36px;
  }
  #footer .container p,
  #footer .container a {
    text-align: center;
  }
  .logoHeader {
    max-height: 60px;
  }
  .additionalSmall {
    height: 91px;
  }
  .heroContact h1 {
    font-size: 40px;
    line-height: 130%;
  }
  .middleContact h2 {
    font-size: 36px;
    line-height: 130%;
  }
  .middleContact h3 {
    font-size: 36px;
    line-height: 130%;
  }
  .middleContact p {
    font-size: 18px;
    line-height: 130%;
    padding: 20px 0 !important;
  }
  .middleContact .row div {
    padding: 20px 16px;
    gap: 10px;
  }
  .middleContact h5 {
    font-size: 28px;
  }
  .middleContact .row div p {
    padding: 10px 0 !important;
  }
  .googleMap iframe {
    height: 250px;
  }
  .contactusContact {
    padding-top: 12rem;
  }
  .section3 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .technologies {
    margin-top: 40px;
  }
  .shoptech {
    margin: 20px 0;
  }
  .middleContact .row div p {
    justify-content: center !important;
  }
  .middleContact p span {
    width: unset;
  }
  .CookieConsent {
    justify-content: center !important;
    text-align: center;
  }
  body {
    overflow-x: hidden;
  }
  .poprawka p {
    width: 130px;
  }
  .poprawka div {
    font-size: 16px;
  }

  #footer {
    width: 100vw;
    overflow-x: hidden;
  }
  .footerInner .centerMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .reviews {
    padding-left: 10px;
  }
  #root {
    width: 100vw;
    overflow-x: hidden;
  }
}
